











import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  components: {
  },
  data() {
    return {
      index: 0,
    }
  },

  computed: {
    current(): any {
      return this.$store.state.current
    },
  },

  methods: {
    register() {
      this.$router.push('/register')
    },
  },
  filters: {
    dtFormat(datetime: number) {
      const time = moment(datetime, 'YYYYMMDDHHmmss')
      return time.add(8,'hours').format('YYYY年M月D日HH时')
    }
  }
})
